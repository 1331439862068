<template>
  <section class="scrisoare-medicala-modal" @mousedown="_clickModal">
    <textarea
      name=""
      :disabled="isExisting"
      id=""
      cols="30"
      rows="10"
      v-model="$store.state.textScrisoare"
      placeholder=" text scrisoare medicala"
    ></textarea>

    <div class="flex flex-between">
      <delete-tratament-btn
        @delete-tratament="_deleteTratament"
        @set-delete-mode="_checkDelete"
      />

      <button
        class="print-btn flex m-left-auto pointer"
        @click="savePrint"
        :disabled="!$store.state.textScrisoare"
      >
        PRINT
      </button>
    </div>
  </section>
</template>

<script>
  import { historyModal } from '../mixins/history-modal';
  import { mapState } from 'vuex';

  export default {
    name: 'scrisoare-medicala-modal',
    mixins: [historyModal],

    data () {
      return {
        isExisting: false
      };
    },

    mounted () {
      if (this.$store.state.textScrisoare) {
        this.isExisting = true;
      }
    },

    computed: {
      ...mapState({
        isEditScrisoare: 'isEditScrisoare'
      })
    },

    methods: {
      savePrint () {
        if (!this.isDelete) {
          if (this.isEditScrisoare) {
            window.print();
            this.isEditScrisoare &&
              this.$store.commit('SET_KEY_VALUE', {
                key: 'isEditScrisoare',
                value: false
              });
            this.$store.commit('SET_MODAL', {
              name: undefined,
              value: false
            });
          } else {
            this.$store.dispatch('save_scrisoare');
          }
        }
      }
    },

    destroyed () {
      this.$store.commit('SET_KEY_VALUE', {
        key: 'printComponentName',
        value: 'fisaPacient'
      });
    }
  };
</script>

<style lang="less">
  @import "../assets/styles/var";
  .scrisoare-medicala-modal {
    padding: 36px;
    background-color: white;
    border-radius: 8px;

    textarea {
      padding: 12px;
      margin-bottom: 16px;
      resize: none;
      border-radius: 4px;
      width: 500px;
      border-color: @medium-grey;
      color: rgba(0, 0, 0, 0.7);
      font-size: 1.6rem;
    }

    .print-btn {
      max-height: 32px;
      padding: 8px 24px;
      font-size: 1.5rem;
      font-weight: bold;
      color: white;
      background-color: @main-green;
      border: none;
      border-radius: 4px;
      transition: background-color 0.1s ease-in-out;
      &:hover {
        background-color: darken(@main-green, 20%, relative) !important;
      }
    }
    button[disabled] {
      background-color: @dark-grey;
      &:hover {
        background-color: @dark-grey!important;
      }
    }
  }
</style>
